import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import EventModel from '../../../../utils/tracking/models';
import loadable from '@loadable/component';

const CategoryLink = loadable(() => import('./categoryLink'));
const Link = loadable(() => import('react-router-dom/Link'));

class Categories extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeChannel: null,
      activeSubscribe: false
    };

    this.setActiveChannel = this.setActiveChannel.bind(this);
    this.setActiveSubscribe = this.setActiveSubscribe.bind(this);
    this.setInActiveState = this.setInActiveState.bind(this);
    this.resetChannelCloseTimer = this.resetChannelCloseTimer.bind(this);
    this.setChannelCloseTimer = this.setChannelCloseTimer.bind(this);
    this.channelTimeout = undefined;

    this.handleChannelTitleClick = this.handleChannelTitleClick.bind(this);
    this.resetSubscribeCloseTimer = this.resetSubscribeCloseTimer.bind(this);
    this.setSubscribeCloseTimer = this.setSubscribeCloseTimer.bind(this);
    this.subscribeTimeout = undefined;
    this.clickTracking = this.clickTracking.bind(this);
  }

  setActiveChannel(title) {
    let activeChannel = null;
    if (this.state.activeChannel !== title) {
      activeChannel = title;
    }
    this.setState({ activeChannel, activeSubscribe: false });
  }

  setActiveSubscribe() {
    this.setState({
      activeSubscribe: !this.state.activeSubscribe,
      activeChannel: null
    });
  }

  setInActiveState() {
    this.setState({
      activeChannel: null,
      activeSubscribe: false
    });
  }

  setChannelStyle(title, index) {
    const style = {};
    const { activeChannel } = this.state;
    if (title === activeChannel) {
      const container = document
        .querySelector(`.masthead__channel-container--${index}`)
        .querySelector('.masthead__channel-items');
      const width = container && container.offsetWidth;
      style.width = `${width}px`;
      style.maxWidth = `${width}px`;
    }
    return style;
  }

  setSubscribeStyle() {
    const style = {};
    if (this.state.activeSubscribe) {
      let width = 0;
      const container = document.querySelectorAll('.masthead__subtopic');
      container.forEach(item => {
        width += item.offsetWidth;
      });
      style.width = `${width}px`;
      style.maxWidth = `${width}px`;
    }
    return style;
  }

  setChannelCloseTimer() {
    this.channelTimeout = setTimeout(this.setActiveChannel, 2000);
  }

  setSubscribeCloseTimer() {
    if (this.state.activeSubscribe === true) {
      this.subscribeTimeout = setTimeout(() => {
        this.setState({
          activeSubscribe: false,
          activeChannel: null
        });
      }, 2000);
    }
  }

  setActive(e) {
    const cl = 'masthead__nav-item--active';
    const classList = get(e, 'currentTarget.classList', []);
    if (!classList.contains(cl)) {
      classList.add(cl);
    }
  }

  handleChannelTitleClick(event, title) {
    if (!this.state.activeChannel) {
      event.preventDefault();
      this.setActiveChannel(title);
    }
  }

  resetChannelCloseTimer() {
    if (this.channelTimeout) {
      clearTimeout(this.channelTimeout);
    }
  }

  resetSubscribeCloseTimer() {
    if (this.subscribeTimeout) {
      clearTimeout(this.subscribeTimeout);
    }
  }

  clickTracking(data) {
    const clickEvent = new EventModel(
      data || 'none',
      `header bar`,
      `click`,
      `nav ${data || 'none'}`,
      `topOfPage`
    );
    clickEvent.triggerTrackingEvent(
      'default-click',
      clickEvent.getTrackingObject()
    );
  }

  render() {
    const {
      active,
      topics,
      channels,
      activeCategory,
      trackClick = () => {}
    } = this.props;

    const { activeSubscribe, activeChannel } = this.state;

    return (
      <nav className={`masthead__nav ${active ? 'masthead__nav--active' : ''}`}>
        <ul className="masthead__nav-list">
          {topics.map((topic, index) => (
            <li
              onClick={this.setActive}
              key={`topic-${index}`}
              className={`masthead__nav-item ${
                topic.bold ? 'masthead__nav-item--bold' : ''
              } ${
                activeCategory === topic.url ? 'masthead__nav-item--active' : ''
              } ${topic.subtopics ? 'masthead__nav-item--subtopics' : ''} ${
                (activeSubscribe && !topic.subtopics) || activeChannel
                  ? 'masthead__nav-item--disabled'
                  : ''
              } ${
                topic.removeOnScale ? 'masthead__nav-item--remove-on-scale' : ''
              } ${
                topic.removeAlways ? 'masthead__nav-item--remove-always' : ''
              } ${topic.title === 'Premium' ? 'premium' : ''}`}
            >
              <CategoryLink
                mobileTitle={topic.mobileTitle || null}
                topic={topic}
                index={index}
                setInActiveState={this.setInActiveState}
                disabled={Boolean(activeSubscribe || activeChannel)}
                trackClick={
                  get(topic, 'subtopics', false)
                    ? () => {
                        this.clickTracking(get(topic, 'title', ''));
                      }
                    : trackClick
                }
              />
            </li>
          ))}
          {Boolean(channels.length) && (
            <li key="spacer" className="masthead__spacer" />
          )}
          {channels.map((channel, idx) => {
            const channelStyle = this.setChannelStyle(channel.title, idx);
            const isActive = Boolean(channelStyle.width);
            return (
              <li
                key={`channel-${idx}`}
                className={`masthead__channel ${
                  isActive ? 'masthead__channel--active' : ''
                } ${activeSubscribe ? 'masthead__channel--disabled' : ''}`}
                onMouseEnter={() => {
                  this.resetChannelCloseTimer();
                  this.setActiveChannel(channel.title);
                }}
                onMouseLeave={() => {
                  this.setChannelCloseTimer();
                  this.setActiveChannel(channel.title);
                }}
              >
                <div className="masthead__channel-logo">
                  <Link className="masthead__channel-title" to="/fcw">
                    {channel.title}
                  </Link>
                </div>
                <div
                  className={`masthead__channel--container masthead__channel-container--${idx}`}
                  style={channelStyle}
                >
                  <ul className="masthead__channel-items">
                    {channel.items.map((item, index) => (
                      <li
                        key={`topic-${index}`}
                        className="masthead__channel-item"
                      >
                        <CategoryLink
                          topic={item}
                          index={index}
                          setInActiveState={this.setInActiveState}
                          trackClick={trackClick}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

Categories.propTypes = {
  active: PropTypes.bool.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  channels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeCategory: PropTypes.string.isRequired,
  trackClick: PropTypes.func.isRequired
};

export default Categories;
